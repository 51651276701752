$(document).ready(function() {

    /*
        GLOBAL
     */
    $(".fancybox").fancybox();

    /*
        MODULES
     */
    $('header#header .mod-product-categories .owl-carousel').owlCarousel({
        items: 5,
        loop:false,
        nav: true,
        margin:30,
        autoWidth:true,
        navText: ["<img src='/wp-content/themes/tiz-azurlign/assets/svg/layout/icon-arrow-right-blue.svg'>","<img src='/wp-content/themes/tiz-azurlign/assets/svg/layout/icon-arrow-left-blue.svg'>"]
    });

    $('#main .mod-product-categories .owl-carousel').owlCarousel({
        items: 1,
        loop:false,
        nav: true,
        margin:30,
        autoWidth:true,
        navText: ["<img src='/wp-content/themes/tiz-azurlign/assets/svg/layout/icon-arrow-left-blue.svg'>","<img src='/wp-content/themes/tiz-azurlign/assets/svg/layout/icon-arrow-right-blue.svg'>"],
        responsive:{
            767:{
                items:1
            },
            991:{
                items:2
            },
            1159:{
                items:3
            },
            1499:{
                items:4,
                nav: false
            }
        }
    });

    $('#main #related-products .owl-carousel').owlCarousel({
        items: 1,
        loop:false,
        nav: true,
        margin:30,
        navText: ["<img src='/wp-content/themes/tiz-azurlign/assets/svg/layout/icon-arrow-left-blue.svg'>","<img src='/wp-content/themes/tiz-azurlign/assets/svg/layout/icon-arrow-right-blue.svg'>"],
        responsive:{
            480:{
                items:1
            },
            767:{
                items:2
            },
            991:{
                items:3
            },
            1159:{
                items:4,
                nav: false
            },
        }
    });


    $('#main .mod-slider .owl-carousel').owlCarousel({
        items: 1,
        loop: false,
        nav: false,
        dots: true,
        animateOut: 'fadeOut',
        dotsClass: 'container owl-dots',
        autoplay:true,
        autoplayTimeout: 3000,
        autoplayHoverPause:true
    });

    $('header#header #link-collections').on('click', function() {
        $('header#header .mod-product-categories').toggle();

        $('header#header .mod-product-categories .owl-carousel').trigger('refresh.owl.carousel');

        if ($(this).hasClass('active'))
            $(this).removeClass('active');
        else
            $(this).addClass('active');

        return false;
    });

    $('header#header #link-collections-mobile').on('click', function() {
       $(this).next().find('ul').slideToggle();

        return false;
    });


    /*
        PAGE PRODUIT
     */

    $('#product-content #product-attributes .attribute .attribute-values .attribute-value'). on('click', function() {
        var img_src = $(this).attr('href');

        $(this).closest('.attribute').find('.preview').css("background-image", "url("+img_src+")").fadeToggle();
       return false;
    });



    $('#product-content #product-attributes .attribute .preview').on('click', function(e) {
        $(this).fadeToggle();
        return false;
    });

    $('#product-content #product-attributes .attribute .preview').mouseleave(function() {
        if ($(this).is(':visible')) {
            $(this).fadeToggle();
        }
    });

    $("#product-images #product-thumbnails .owl-carousel").owlCarousel({
        items: 4,
        loop: false,
        mouseDrag: false,
        touchDrag: false,
        pullDrag: false,
        autoplay: false,
        margin: 10,
        nav: true,
        navText: ["<img src='/wp-content/themes/tiz-azurlign/assets/svg/layout/icon-arrow-left-grey.svg'>","<img src='/wp-content/themes/tiz-azurlign/assets/svg/layout/icon-arrow-right-grey.svg'>"],
        responsive:{
            992:{
                items:5,
                margin: 20,
            }
        }

    });

    $(document). on('click', '#product-images #product-thumbnails .product-thumbnail', function() {
        var img_src = $(this).attr('href');

        $('#product-images #product-image-wrapper .product-image').attr('src', img_src);
        $('#product-images #product-image-wrapper .zoom').attr('href', img_src);
        return false;
    });

    // OFI
    objectFitImages();
});